import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import ReactMarkdown from 'react-markdown';
import { Container, Row, Col } from 'react-grid-system';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/patient-privacy-hero.jpg';
import TwoCol from '../components/rows/twocol/index';
import Follow from '../components/rows/cta/follow';

export default function Privacy({ data }) {
  return (
    <Layout>
      <Seo
        title="Privacy at PACE"
        description="Learn about PACE’s commitment to protect our patients’ privacy & treat everyone with respect. This includes 
            the various steps we take to protect your personal health information.  "
      />
      <Hero title="Privacy at PACE" banner={Banner} />
      {data.allStrapiPrivacyAtPace.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <TwoCol
            mailto
            newTab
            pdf
            title={node.privacy1.title}
            description={node.privacy1.description}
            link={node.privacy1.button_title}
            href={node.privacy1.button_link}
            image={node.privacy1.image.localFile.publicURL}
          />
          <div className="bg-gray-100 py-56 my-56 privacy">
            <Container>
              <Row>
                <ReactMarkdown
                  className="flex justify-center w-full font-bold"
                  components={{ p: 'h3' }}
                  children={node.privacy2_title}
                  linkTarget="_blank"
                  rel="noreferrer"
                />
              </Row>
              <Row className="mt-5">
                <Col md={6} sm={12}>
                  <ReactMarkdown
                    children={node.privacy2_description}
                    linkTarget="_blank"
                    rel="noreferrer"
                  />
                </Col>
                <Col md={6} sm={12}>
                  <ReactMarkdown
                    children={node.privacy2_list}
                    linkTarget="_blank"
                    rel="noreferrer"
                  />
                </Col>
              </Row>
            </Container>
          </div>
          {/* <TwoCol key={node.privacy3.id}
                        paceQuiz
                        reverse
                        title={node.privacy3.title}
                        description={node.privacy3.description}
                        link={node.privacy3.button_title}
                        href={node.privacy3.button_link}
                        image={node.privacy3.image.localFile.publicURL}
                    /> */}
        </React.Fragment>
      ))}
      <div className="mt-56">
        <Follow />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query Privacy {
    allStrapiPrivacyAtPace {
      edges {
        node {
          id # id of the node
          privacy1 {
            id
            title
            description
            button_link
            button_title
            image {
              localFile {
                publicURL
              }
            }
          }
          privacy2_title
          privacy2_description
          privacy2_list
        }
      }
    }
  }
`;
